body {
  margin: 0;
  padding: 0;
  
  color: #111;
  background-color:white;
 /*  background-image: url('https://nopalcod.com/services/adhome_imagenes/casafondo.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover; */
}
body * {
  transition: all 0.300s ease-in-out;
}

@import url('https://fonts.googleapis.com/css?family=Raleway:200');
@import url('https://fonts.googleapis.com/css?family=Caveat');
@import url('https://fonts.googleapis.com/css?family=Lato');

.card{
  width:17rem;
  
}

.card:hover{
  box-shadow: 5px 10px 20px 1px rgba(0,0,0,0.253) !important;
}

.card-body{
  padding: 3rem 0 !important;
}

.card-text{
  font-size: .9rem;
  padding: 0.2rem 1rem;
}

.container-fluid.row{
  padding-top: 6rem;
}

.overflow{
  overflow: hidden;
}

.card-img-top{
  transform: scale(1);
  transition: transform 0.5s ease;
}

/* .card-img-top:hover{
  transform:scale(1.8);
} */
.tamanio_imagen{
  max-width: 45%;
  max-height: 45%;
}

/*------------- NAVIGATION BAR -------------------*/

nav {
  position: fixed;
  z-index: 99;
  background: rgb(255, 0, 0);
  z-index: 99;
  font-family: Raleway;
  width: 100%;
  margin: 0 auto;  
  padding: 12px 0;
  display: grid;
  grid-template-columns: 2fr 1fr;
  -webkit-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
}


nav ul {
  width: 100%;
  display: grid;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
} 

nav ul:first-of-type{
  grid-template-columns: repeat(2, 1fr);
}
nav ul:first-of-type li{
  font-size: 1.4rem;
  font-weight: bold;
  text-indent: 50px;
}
nav ul:first-of-type li span {
  font-weight: 500;
  color: #444;
}

nav ul:last-of-type{
  grid-template-columns: repeat(6, 1fr);
}


nav ul li a{
  color:rgb(255, 255, 255);
  text-decoration: none; 
  font-weight: bold;
  display: block;

}


/*------------- HEADER -------------------*/





.contact {
 background: rgb(0, 99, 248);
 display: block;
 color: #fff;
 font-weight: bold;
 width: 50%;
 margin: 0 auto;
 text-align: center;
 padding: 12px;
 border-radius: 10px;
 text-decoration: none; 
 -webkit-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
 -moz-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
 box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
}


 /*------------- MAIN SECTION -------------------*/




footer {
  background: rgb(0, 99, 248);
  color: #fff;
  padding: 20px;
  margin-top: 80px;
  display: grid;
  grid-template-columns:1fr 1fr 1fr;
  align-items: center;
  text-align: center;
 
}

footer ul {
  
  list-style: none;
  display: grid;
  padding: 0;
  margin: 0;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
}

footer ul li a {
  color: #eee;
}




@media (max-width:599px) and (min-width:0px) {
  nav {
   text-align: center;
    display: grid;
    grid-template-columns: 1fr;
  }
  
  nav ul {
    margin-bottom: 10px;
    
  }
  nav ul:first-of-type {
    border-bottom:1px solid #111;
    padding-bottom: 10px; 
  }
  
  nav ul:last-of-type li{
   text-align: center;
   width: 100%;
   padding-top: 10px;
   
  }
  
 
  footer {
    padding: 20px 0;
    text-align: center;
    grid-template-columns:1fr;
  
  }
  footer ul li a {
 font-size: 1.8rem;
  }
  
  
}