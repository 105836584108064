@import url(https://fonts.googleapis.com/css?family=Raleway:200);
@import url(https://fonts.googleapis.com/css?family=Caveat);
@import url(https://fonts.googleapis.com/css?family=Lato);
body {
  margin: 0;
  padding: 0;
  
  color: #111;
  background-color:white;
 /*  background-image: url('https://nopalcod.com/services/adhome_imagenes/casafondo.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover; */
}
body * {
  transition: all 0.300s ease-in-out;
}

.card{
  width:17rem;
  
}

.card:hover{
  box-shadow: 5px 10px 20px 1px rgba(0,0,0,0.253) !important;
}

.card-body{
  padding: 3rem 0 !important;
}

.card-text{
  font-size: .9rem;
  padding: 0.2rem 1rem;
}

.container-fluid.row{
  padding-top: 6rem;
}

.overflow{
  overflow: hidden;
}

.card-img-top{
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

/* .card-img-top:hover{
  transform:scale(1.8);
} */
.tamanio_imagen{
  max-width: 45%;
  max-height: 45%;
}

/*------------- NAVIGATION BAR -------------------*/

nav {
  position: fixed;
  z-index: 99;
  background: rgb(255, 0, 0);
  z-index: 99;
  font-family: Raleway;
  width: 100%;
  margin: 0 auto;  
  padding: 12px 0;
  display: grid;
  grid-template-columns: 2fr 1fr;
box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
}


nav ul {
  width: 100%;
  display: grid;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
} 

nav ul:first-of-type{
  grid-template-columns: repeat(2, 1fr);
}
nav ul:first-of-type li{
  font-size: 1.4rem;
  font-weight: bold;
  text-indent: 50px;
}
nav ul:first-of-type li span {
  font-weight: 500;
  color: #444;
}

nav ul:last-of-type{
  grid-template-columns: repeat(6, 1fr);
}


nav ul li a{
  color:rgb(255, 255, 255);
  text-decoration: none; 
  font-weight: bold;
  display: block;

}


/*------------- HEADER -------------------*/





.contact {
 background: rgb(0, 99, 248);
 display: block;
 color: #fff;
 font-weight: bold;
 width: 50%;
 margin: 0 auto;
 text-align: center;
 padding: 12px;
 border-radius: 10px;
 text-decoration: none;
 box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
}


 /*------------- MAIN SECTION -------------------*/




footer {
  background: rgb(0, 99, 248);
  color: #fff;
  padding: 20px;
  margin-top: 80px;
  display: grid;
  grid-template-columns:1fr 1fr 1fr;
  align-items: center;
  text-align: center;
 
}

footer ul {
  
  list-style: none;
  display: grid;
  padding: 0;
  margin: 0;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
}

footer ul li a {
  color: #eee;
}




@media (max-width:599px) and (min-width:0px) {
  nav {
   text-align: center;
    display: grid;
    grid-template-columns: 1fr;
  }
  
  nav ul {
    margin-bottom: 10px;
    
  }
  nav ul:first-of-type {
    border-bottom:1px solid #111;
    padding-bottom: 10px; 
  }
  
  nav ul:last-of-type li{
   text-align: center;
   width: 100%;
   padding-top: 10px;
   
  }
  
 
  footer {
    padding: 20px 0;
    text-align: center;
    grid-template-columns:1fr;
  
  }
  footer ul li a {
 font-size: 1.8rem;
  }
  
  
}
.marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 100%;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  &:hover {
    z-index: 1;
  }
}

.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #00cae9;
  position: absolute;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
.pin:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #e6e6e6;
  position: absolute;
  border-radius: 50%;
}

.bounce {
  -webkit-animation-name: bounce;
          animation-name: bounce;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.pulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  -webkit-transform: rotateX(55deg);
          transform: rotateX(55deg);
  z-index: -2;
}
.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  -webkit-animation: pulsate 1s ease-out;
          animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #00cae9;
  -webkit-animation-delay: 1.1s;
          animation-delay: 1.1s;
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
            transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
            transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@-webkit-keyframes bounce {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px) rotate(-45deg);
            transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(30px) rotate(-45deg);
            transform: translateY(30px) rotate(-45deg);
  }

  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
            transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
            transform: translateY(0) rotate(-45deg);
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px) rotate(-45deg);
            transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(30px) rotate(-45deg);
            transform: translateY(30px) rotate(-45deg);
  }

  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
            transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
            transform: translateY(0) rotate(-45deg);
  }
}
  
